import auth from '@udacity/ureact-hoth';

import { getActiveSubscriptionCookie } from '~/features/cookies/utils/get-active-subscription-cookie';

import {
  getViewerCity,
  getViewerCountryCode,
  getViewerRegionCode,
} from '../../internationalization/utils/get-viewer-geo-codes';
import { isEuCountryCode } from '../../internationalization/utils/is-eu-country-code';

import { getGclid } from './get-gclid';

export function getDefaultAnalyticProperties() {
  const isAuthenticated = auth.isAuthenticated();
  const userActiveSubscription = getActiveSubscriptionCookie();

  return {
    version: '2',
    cta_location:
      window.location.pathname === '/' ? (isAuthenticated ? 'Dashboard' : 'Homepage') : window.location.pathname,
    user_status: isAuthenticated ? 'logged_in' : 'logged_out',
    user_has_active_subscription: userActiveSubscription ? true : false, // userActiveSubscription can be undefined
    site_identifier: 'Next.Sites',
    gclid: getGclid(),
    ...getMarketoProperties(),
  };
}

export function getGeoAnalyticsProperties() {
  const countryCode = getViewerCountryCode();
  const regionCode = getViewerRegionCode();
  const city = getViewerCity();
  return {
    countryCode,
    // Note: adding this duplicate `country` field to see if this resolves the Amplitude/Segment geo discrepancy.
    country: countryCode,
    regionCode,
    city,
    isEU: countryCode ? isEuCountryCode(countryCode) : undefined,
  };
}

export function getMarketoProperties() {
  if (typeof window === 'undefined') {
    return {};
  }

  let marketoProperties: Record<string, string> = {};
  const params = new URLSearchParams(window.location.search);
  const utmSource = params.get('utm_source');
  const utmCampaign = params.get('utm_campaign');
  const utmMedium = params.get('utm_medium');

  if (utmSource) {
    marketoProperties.utm_source = utmSource;
  }

  if (utmCampaign) {
    marketoProperties.utm_campaign = utmCampaign;
  }

  if (utmMedium) {
    marketoProperties.utm_medium = utmMedium;
  }

  return marketoProperties;
}
